import { AkJuraBackofficeConfig } from './environment.interface';
import { sharedEnvironment } from './environment.shared';
import { InteractionType } from '@azure/msal-browser';

const apiHost = 'https://api.ak-jura.stage.cluster.rrooaarr.cloud/api';
export const environment: AkJuraBackofficeConfig = {
    ...sharedEnvironment,
    production: true,
    apiHost: apiHost,
    msalConfiguration: {
        auth: {
            clientId: '94631fb4-ea65-4e09-9766-d86ef3a727e9',
            authority:
                'https://akjurastage.b2clogin.com/akjurastage.onmicrosoft.com/B2C_1_login',
            knownAuthorities: ['akjurastage.b2clogin.com'],
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri:
                'https://backoffice.ak-jura.stage.cluster.rrooaarr.cloud',
            redirectUri: 'https://backoffice.ak-jura.stage.cluster.rrooaarr.cloud',
        },
    },
    passwordResetAuthority:
        'https://akjurastage.b2clogin.com/akjurastage.onmicrosoft.com/B2C_1_reset',
    msalGuardConfiguration: {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['https://akjurastage.onmicrosoft.com/webapi/Admin'],
        },
    },
    msalInterceptorConfiguration: {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            [
                `${apiHost}\*`,
                ['https://akjurastage.onmicrosoft.com/webapi/Admin'],
            ],
        ]),
    },
};
